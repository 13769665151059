import React from 'react'
import UBOThemeProvider from 'ui/src/mui/UBOThemeProvider'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { BrowserRouter } from 'react-router-dom'
import { Sidebar, SidebarProps } from 'ui'

export default function Root({ props }: { props: SidebarProps }) {
  const { nonce } = props
  const cache = createCache({
    key: 'css',
    nonce,
    prepend: true,
  })

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <CacheProvider value={cache}>
        <UBOThemeProvider>
          <BrowserRouter>
            <Sidebar {...props} />
          </BrowserRouter>
        </UBOThemeProvider>
      </CacheProvider>
    </React.Suspense>
  )
}
