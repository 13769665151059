import React from 'react'
import GroupMenuItem from 'ui/src/components/menu/GroupMenuItem'
import LinkMenuItem from 'ui/src/components/menu/LinkMenuItem'
import SideMenu from 'ui/src/components/menu/SideMenu'

export type SidebarProps = {
  appIcon: string | React.ReactNode
  portal: string
  appVersion: string
  menuItems: MenuItemProps[] | React.ReactNode
  nonce?: string
  newFont?: boolean
  children?: React.ReactNode
}

export type MenuItemProps = {
  name: string
  icon?: string | React.ReactNode
  link: string
  reload?: boolean
  active?: () => boolean
  subItems?: MenuItemProps[] | React.ReactNode
  target?: string
}

export default function Sidebar({
  appIcon,
  portal,
  appVersion,
  menuItems,
  children,
  newFont = false,
}: SidebarProps) {
  return (
    <SideMenu
      appIcon={
        typeof appIcon === 'string' ? (
          <img src={appIcon} width={20} height={20} />
        ) : (
          appIcon
        )
      }
      appName={portal}
      appVersion={appVersion}
      newFont={newFont || false}
    >
      {children}
      {generateMenuItems(menuItems)}
    </SideMenu>
  )
}

function generateMenuItems(
  menuItems: MenuItemProps[] | React.ReactNode,
): React.ReactNode {
  if (React.isValidElement(menuItems)) {
    return menuItems
  }
  if (!menuItems || (menuItems as MenuItemProps[]).length === 0) {
    return
  }
  return (menuItems as MenuItemProps[]).map((item, i) => {
    if (item.subItems) {
      return (
        <GroupMenuItem
          key={i}
          text={item.name}
          icon={
            typeof item.icon === 'string' ? (
              <img src={item.icon} width={24} height={24} />
            ) : (
              item.icon
            )
          }
        >
          {generateMenuItems(item.subItems)}
        </GroupMenuItem>
      )
    }
    return (
      <LinkMenuItem
        key={i}
        icon={
          typeof item.icon === 'string' ? (
            <img src={item.icon} width={24} height={24} />
          ) : (
            item.icon
          )
        }
        text={item.name}
        href={item.link}
        reload={item.reload}
        active={item.active}
        target={item.target}
      />
    )
  })
}
